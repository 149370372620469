.single__summary__info__blink {
  animation: blink 2s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
