/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
} */

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.conic {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  overflow: hidden;
  padding: 0px;

  &::before {
    content: "";
    border-radius: 9999px;
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: rgba(2, 171, 232, 0.05);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(transparent, rgb(0, 187, 164, 1), transparent 30%);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #0e1925;
    border-radius: 9999px;
  }
}

@keyframes opacityChange {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
