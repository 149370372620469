/* Reset MUI styles */
.custom-button {
  border: 0px;
  box-shadow: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

.custom-button {
  position: relative;
}

.custom-button .line {
  display: block;
  position: absolute;
  transition: all 0.5s ease;
}

.custom-button .line.primary {
  background-color: #02acea;
}

.custom-button .line.secondary {
  background-color: #1b7f76;
}

.custom-button .line.tl {
  top: 0;
  left: 0;
  width: 5px;
  height: 2px;
}

.custom-button .line.tr {
  top: 0;
  right: 0;
  width: 5px;
  height: 2px;
}

.custom-button .line.bl {
  bottom: 0;
  left: 0;
  width: 5px;
  height: 2px;
}

.custom-button .line.br {
  bottom: 0;
  right: 0;
  width: 5px;
  height: 2px;
}

.custom-button:hover .line.tl,
.custom-button:hover .line.tr,
.custom-button:hover .line.bl,
.custom-button:hover .line.br {
  width: 100%;
}

.custom-button .line.lt {
  top: 0;
  left: 0;
  width: 2px;
  height: 5px;
}

.custom-button .line.rt {
  top: 0;
  right: 0;
  width: 2px;
  height: 5px;
}

.custom-button .line.lb {
  bottom: 0;
  left: 0;
  width: 2px;
  height: 5px;
}

.custom-button .line.rb {
  bottom: 0;
  right: 0;
  width: 2px;
  height: 5px;
}

.custom-button:hover .line.lt,
.custom-button:hover .line.rt,
.custom-button:hover .line.lb,
.custom-button:hover .line.rb {
  height: 100%;
}

.custom-button .line.inner-top {
  height: 1px;
  top: 5px;
  left: 5px;
  right: 5px;
}

.custom-button .line.inner-bottom {
  height: 1px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}

.custom-button .line.inner-left {
  width: 1px;
  top: 5px;
  bottom: 5px;
  left: 5px;
}

.custom-button .line.inner-right {
  width: 1px;
  top: 5px;
  bottom: 5px;
  right: 5px;
}
