// colors
$cr-white: hsl(0, 0%, 100%)
$cr-blue-1: #02ACEA
$cr-blue-2: rgba(255,255,255, 0.05)
$cr-blue-3: transparent

// subtle colors
$cr-blue-500a: hsla(0, 0%, 0%, 0.5)

// gradients
$gradient-lin-1: linear-gradient(to right, transparent, $cr-blue-1)
$gradient-lin-2: linear-gradient(to right, $cr-blue-1, transparent)
$gradient-lin-3: linear-gradient(to bottom, $cr-blue-1, transparent)
$gradient-lin-4: linear-gradient(to bottom, transparent, $cr-blue-1)

// components
$page_cr: $cr-white
$page_bd-cr: $cr-blue-3

$cl-card_cr: $cr-white
$cl-card_bd-cr: $cr-blue-2
$cl-card__line_bd-cr: $cr-blue-1



.cl-main
  // grid
  display: grid
  
  align:
    items: center
    
  &__cl-card
    // position
    margin:
      inline: auto
    
    // size
    max:
      width: 100%
      
.cl-card
  $anim-duration: 5s
  $line-thicknes: 2px
  
  color: $cl-card_cr

  border: 1px solid hsla(196, 98%, 46%, 0.2)
  
  background:
    color: $cl-card_bd-cr
   
  
  // position
  position: relative
    
  // size
  width: 100%
  height: 58px
    
  // visual
  overflow: hidden
  
  box:
    // shadow: 0 0 2em $cr-blue-500a
    
  &__line
    // position
    position: absolute
    
    animation: 2s 2s linear infinite
    
    &_left
      // animation
      animation:
        name: to-top
      
      // color
      background: $gradient-lin-3
      
      // position
      left: 0
      bottom: -100%
      
      // size
      width: $line-thicknes
      height: 100%
      
    &_right
      // animation
      animation:
        name: to-bottom
      
      // color
      background: $gradient-lin-4
      
      // position
      right: 0
      top: -100%
      
      // size
      width: $line-thicknes
      height: 100%
      
    &_top
      // animation
      animation:
        delay: 3s
        name: to-right
        
      // color
      background: $gradient-lin-1
      
      // size
      width: 100%
      height: $line-thicknes
      
      // position
      top: 0
      left: -100%
      
    &_bottom
      // animation
      animation:
        delay: 3s
        name: to-left
        
      // color
      background: $gradient-lin-2
      
      // position
      bottom: 0
      right: -100%
      
      // size
      width: 100%
      height: $line-thicknes
      
@keyframes to-top
  to
    // position
    bottom: 200%
    
@keyframes to-bottom
  to
    // position
    top: 200%
    
@keyframes to-left
  to
    // position
    right: 200%
    
@keyframes to-right
  to
    // position
    left: 200%