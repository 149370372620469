* {
  scroll-behavior: smooth;
}

body,
#root {
  min-height: 100dvh;
}

.blink-fade-regular {
  animation: blink 2s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
