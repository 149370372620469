/* .ribbon:before {
  content: "";
  z-index: -1;
  overflow: hidden;
  transform: rotate(-135deg);
  width: 120px;
  display: block;
  background: #00e5ff;
  background: linear-gradient(#80f2ff 0%, #00e5ff 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 34px;
  right: -16px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px, 110px 30px);
  height: 20px;
  width: 120px;
} */

.ribbon:after {
  content: "COMMING SOON";
  z-index: 1;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 120px;
  display: block;
  background: #00e5ff;
  background: linear-gradient(#80f2ff 0%, #00e5ff 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px;
  right: -30px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px, 110px 30px);
}

.ribbon.future:after {
  background: #00ffaa;
  background: linear-gradient(#80fdd3 0%, #00ffaa 100%);
}
