.ghost-hero {
  width: 200px;
  position: absolute;
  bottom: -120px;
  right: 50%;
  z-index: 100;
  transform: translate(50%) rotate(-20deg);
  animation-name: ghost;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  margin: 20px;
}

@keyframes ghost {
  0% {
    bottom: -120px;
    transform: translate(50%) rotate(-20deg);
  }
  25% {
    transform: translate(50%) rotate(-15deg);
  }
  50% {
    bottom: -110px;
    transform: translate(50%) rotate(-20deg);
  }
  75% {
    transform: translate(25%) rotate(-15deg);
  }
  100% {
    bottom: -120px;
    transform: translate(50%) rotate(-20deg);
  }
}

.color-underline {
  margin-bottom: 3.5rem;
  background-size: 100% 15%;
  background-repeat: repeat-x;
  background-position: left 0% bottom 10%;
}

.color-underline.c-primary {
  background-image: linear-gradient(181deg, #24b2e6 0%, #02acea 50%, transparent 54%, transparent 100%);
}

.color-underline.c-secondary {
  background-image: linear-gradient(181deg, #14e5cb 0%, #00bba4 50%, transparent 54%, transparent 100%);
}

.ghost-img {
  filter: drop-shadow(0 3px 15px #000);
}

.ghost-img:hover {
  animation-name: ghostglow;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes ghostglow {
  0% {
    filter: drop-shadow(0 3px 15px black);
  }
  20% {
    filter: drop-shadow(0 3px 20px aqua);
  }
  40% {
    filter: drop-shadow(0 3px 15px lime);
  }
  60% {
    filter: drop-shadow(0 3px 20px white);
  }
  80% {
    filter: drop-shadow(0 3px 15px yellow);
  }
  100% {
    filter: drop-shadow(0 3px 15px black);
  }
}
